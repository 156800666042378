<template>
    <v-dialog :max-width="options.width" :style="{ zIndex: options.zIndex }" v-model="show">
        <v-card>
            <v-card-title class="d-flex align-center pt-5 pb-1 pl-6 pr-2">
                <h3>{{ title }}</h3>
                <v-spacer></v-spacer>
                <v-btn @click="cancel" color="transparent" elevation="0" fab>
                    <v-icon color="#66686D">mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-4 pt-4 pl-6" v-if="!!message">{{ message }}</v-card-text>

            <v-card-actions class="pt-3 pb-5 pl-6">
                <v-btn @click="agree" size="large" color="primary-green">Confirm</v-btn>
                <v-btn @click="cancel" size="large" color="light-grey">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
/**
* Vuetify Confirm Dialog component
*
* Insert component where you want to use it:
*   <confirm ref="refConfirm"></confirm>
*
* Call it:
*   this.$refs.refConfirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {}).catch(function(e){})
* Or use await:
*   if (await this.$refs.refConfirm.open('Delete', 'Are you sure?', { color: 'red' })) {
*       // yes
*   } else {
*       // cancel
*   }
*
* Alternatively you can place it in main App component and access it globally via this.$root.$confirm
*   <template>
*       <v-app>
*           ...
*           <confirm ref="refConfirm"></confirm>
*       </v-app>
*   </template>
*
*   mounted() {
*       this.$root.$confirm = this.$refs.refConfirm.open
*   }
*/
export default {
    data: () => ({
        isConfirmDialogVisible: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
            width: 472,
            zIndex: 200
        }
    }),
    computed: {
        show: {
            get() {
                return this.isConfirmDialogVisible
            },
            set(value) {
                this.isConfirmDialogVisible = value
                if (value === false) {
                    this.cancel()
                }
            }
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    },
    methods: {
        handleKeydown(event) {
            if (this.isConfirmDialogVisible) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    this.agree();
                } else if (event.key === 'Escape') {
                    this.cancel();
                }
            }
        },
        open(title, message, options) {
            this.isConfirmDialogVisible = true
            this.title = title
            this.message = message
            this.options = Object.assign(this.options, options)
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree() {
            this.resolve(true)
            this.isConfirmDialogVisible = false
        },
        cancel() {
            this.reject(false)
            this.isConfirmDialogVisible = false
        }
    }
}
</script>

<style lang="scss" scoped>
.v-overlay {
    z-index: 2400 !important;
}

.v-dialog>.v-overlay__content>.v-card>.v-card-text,
.v-dialog>.v-overlay__content>form>.v-card>.v-card-text {
    font-size: 16px;
}

.mdi-close::before {
    font-size: 24px !important;
}
</style>